.aside-app {

  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.current-menu-item,
      &.current-page-ancestor {
        > a {
          &::before {
            display: block;
          }
        }
      }

      > a {
        position: relative;
        display: block;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.075rem;
        color: $preto100;
        padding: 1rem 0;
        transition: 0.3s;

        &::before {
          display: none;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 6px;
          width: calc(100%);
          z-index: 1;
          background-color: $primary;
          border-radius: 5px;

          @media screen and (max-width: $screen-md) {
            background-color: $secondary;
          }
        }

        &:hover {
          &::before {
            display: block;
          }
        }
      }
    }
  }

  > .lastestnews {
    > .new {
      > .list {
        > .item {
          display: flex;
          border-bottom: 1px solid $preto10;
          padding: 1.5rem 0;

          &:first-child {
            padding-top: 0;
          }

          > .imagebox {
            margin-right: 1.5rem;
    
            > .image {
              width: 7.8rem;
              height: 7.8rem;
              object-fit: cover;
            }
          }
          > .text {
            > .title {
              font-size: 1.6rem;
              line-height: 1.62;
              font-weight: bold;
    
              > .link {
                color: $primary;
                text-decoration: none;
    
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}