.gallery-app {
  > .list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    box-shadow: $shadow-card;

    > .item {
      flex: 33.3%;
      margin-bottom: 0;
      border: 1px solid $preto10;

      > .link {
        > .media-app {
          > img {
            transition: .3s;

            &:hover {
              opacity: .8;
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}