.float-top {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  z-index: 10;
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  line-height: 1;
  border: none;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  background-color: $primary;
  color: rgba($black, 0.8);
  box-shadow: 0 8px 16px rgba($black, 0.4);

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.9);
  }

  &:hover {
    opacity: 0.8;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
