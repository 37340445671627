/*!
Theme Name: Fiorde
Theme URI: https://fiorde.com.br
Author: Rodrigo Cintrão
Description: Tema criado para a Fiorde Group
Version: 1.0
*/

/* VARIABLES */
@import "vars";
@import "colors";

/* RESET AND NORMALIZE */
@import "../node_modules/css-reset-and-normalize/scss/reset-and-normalize";

/* BOOTSTRAP */
@import "../node_modules/bootstrap/scss/bootstrap-grid";

/* FONTAWESOME */
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";

/* Lightbox */
@import "lightbox";

/* Slick */
@import "slick";
@import "slick-theme";


@import "fonts";
@import "animations";
@import "slider-app";
@import "slogan-app";
@import "talkexpert-app";
@import "esg-app";
@import "areasexpertise-app";
@import "totalizers-app";
@import "requestquote-app";
@import "sirius-app";
@import "cases-app";
@import "blog-app";
@import "page-title";
@import "aside-app";
@import "certificates-app";
@import "caption-app";
@import "time-app";
@import "author-app";

@import "aboutus-app";
@import "breadcrumb-app";
@import "button-app";
@import "card-app";
@import "card-footer";
@import "contact-app";
@import "contact-aside";
@import "contact-about";
@import "copy-app";
@import "currencyquote-app";
@import "currencyquote-table";
@import "customers-app";
@import "excerpt-app";
@import "filiais-app";
@import "float-top";
@import "float-whatsapp";
@import "footer-app";
@import "form-contact";
@import "form-price";
@import "header-app";
@import "info-contact";
@import "list-contact";
@import "list-links";
@import "list-social";
@import "main-app";
@import "media-app";
@import "news-app";
@import "override";
@import "page-404";
@import "pagination-page";
@import "pre_loader";
@import "price-app";
@import "search-form";
@import "services-app";
@import "svg-app";
@import "tabs-app";
@import "the-content";
@import "title-app";
@import "general";
@import "gallery-app";
@import "products-app";
@import "projects-app";

// login wordpress
.login {
  background-color: $mercury;

  h1 a {
    background: url("./images/logo.png") transparent no-repeat center /
      contain;
    width: 100%;
    height: 8rem;
  }

  input {
    display: inline-block;
    width: auto;
    height: 4rem;
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 2rem;
    height: 2rem;
  }

  select {
    display: inline-block;
  }
}
