.the-content {
  padding: 3rem 0;
  min-height: 40rem;

  &.-sirius {
    border-bottom: 1px solid $preto10;
  }

  &.-services {
    padding: 3rem 0 0;
  }

  h2,
  h3,
  h4 {
    font-weight: bold;
    line-height: 1.463;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 3.4rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 2rem;
  }

  p {
    margin-bottom: 2.6rem;
  }

  ul {
    padding-left: 2.6rem;
    margin-bottom: 2.6rem;
    list-style-type: disc;

    > li {
      margin-bottom: 1.5rem;
    }
  }
}
