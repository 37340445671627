.list-social {
  > .list {
    display: flex;

    > .item {
      margin-right: 3rem;

      > .link {
        font-size: 3rem;
        line-height: 1;
        color: $primary;

        &:hover {
          opacity: .8;
        }
      }
    }
  }

  &.-footer {
    > .list {
      > .item {
        > .link {
          color: $white;
        }
      }
    }
  }
}
