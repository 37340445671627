.form-price {
  margin-bottom: 3rem;

  &.-center {
    text-align: center;
  }

  div {
    position: relative;
  }

  .row {
    margin-right: -.75rem;
    margin-left: -.75rem;

    > .col-sm-6 {
      padding: 0 .75rem;
    }
  }
}