.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $preto100;
  text-align: center;
  padding: 3rem 0;

  > h1 {
    font-size: 12rem;
    line-height: 1;
  }

  > h2 {
    font-size: 6rem;
    line-height: 1;
  }

  p {
    margin-bottom: 3rem;
  }
}
