.header-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  z-index: 100;
  box-shadow: 0 4px 8px rgba($black, 0.2);

  > .range-header {
    background-color: $primary;
    padding: 1rem 0;
    font-size: 1.4rem;
    line-height: 1.42;
    overflow: hidden;
    transition: 0.3s;

    &.anime {
      height: 0;
      padding: 0;
    }

    > .container {
      display: flex;
      justify-content: space-between;

      > .adress {
        > .link {
          display: flex;
          align-items: center;
          color: $white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          > i {
            font-size: 2rem;
            color: $white;
            margin-right: 0.5rem;
          }
        }
      }

      > .menusecondary {
        > .menu {
          > ul {
            display: flex;

            > li {
              padding: 0 1.5rem;
              border-right: 1px solid $white;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                border-right: none;
                padding-right: 0;
              }

              > a {
                color: $white;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      > .languagecontact {
        display: flex;
        align-items: center;

        > .item {
          border-right: 1px solid $white;
          padding: 0 1.5rem;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
            border-right: none;
          }
        }

        > .language {
          > .list {
            display: flex;
            align-items: center;

            > .item {

              &.-flags {
                > ul {
                  display: flex;

                  > li {
                    text-indent: -999px;
                    overflow: hidden;
                    margin-right: 1.5rem;

                    &:last-child {
                      margin-right: 0;
                    }

                    a {
                      display: block;
                      height: 2rem;
                      width: 3rem;
                      background-size: cover !important; 
                      line-height: 1; 
                    }
                  }

                  > .lang-item-es {
                    > a {
                      background: url('images/espanol.png') no-repeat;
                    }
                  }

                  > .lang-item-pt {
                    > a {
                      background: url('images/portugues.png') no-repeat;
                    }
                  }

                  > .lang-item-en {
                    > a {
                      background: url('images/usa.png') no-repeat;
                    }
                  }
                }
              }

              &:last-child {
                border-right: none;
              }

              > .link {
                display: flex;
                align-items: center;
                color: $white;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }

                > i {
                  font-size: 2rem;
                  color: $white;
                  margin-right: 0.5rem;
                }
              }
            }
          }
        }

        > .contact {
          color: $white;
        }

        > .social {
          padding: 0 1.2rem;

          > .list {
            display: flex;

            > .item {
              display: flex;
              align-items: center;
              margin-right: 1.5rem;

              &:last-child {
                margin-right: 0;
              }

              > .link {
                color: $white;
                text-decoration: none;
                line-height: 1;

                &:hover {
                  opacity: 0.8;
                }

                > i {
                  font-size: 2rem;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  > .container {

    > .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 0;

      > .logo {

        > .link {
          display: block;
          position: relative;
          z-index: 1;

          > .image {
            display: block;
            height: 7.5rem;
          }
  
          &.desktop {
            @media screen and(max-width: $screen-md) {
              display: none;
            }
          }
  
          &.mobile {
            display: none;
  
            @media screen and(max-width: $screen-md) {
              display: block;
            }
          }
        }
      }
  
      > .nav {
        > .bars {
          font-size: 20px;
          color: $primary;
          cursor: pointer;
        }
  
        > .menu {

          @media screen and (max-width: $screen-md) {
            position: fixed;
            width: 80%;
            height: 100vh;
            top: 0;
            right: 0;
            background-color: rgba($primary, 0.95);
            box-shadow: 0 8px 16px rgba($black, 0.4);
            transform: translate3d(110%, 0, 0);
            transition: 0.3s;
            overflow-y: scroll;
            z-index: 10;
            padding-top: 0;
  
            &.active {
              transform: translate3d(0, 0, 0);
            }
          }
  
          > .close {
            color: $white;
            font-size: 20px;
            padding: 15px;
            z-index: 10;
            text-align: right;
            cursor: pointer;
          }
  
          ul {
            display: flex;
  
            @media screen and (max-width: $screen-md) {
              flex-direction: column;
            }

            li.menu-item-has-children {
              > a {
                display: flex;
                align-items: center;
                
                &::after {
                  display: block;
                  content: "";
                  padding: 3px;
                  box-shadow: 1px -1px 0 0 $preto50 inset;
                  border: solid transparent;
                  border-width: 0 0 1px 1px;
                  margin-left: 5px;
                }
              }
            }

            > li.menu-item-has-children {
              > a {
                &::after {
                  transform: rotate(315deg);
                }
              }
            }
  
            > li {
              position: relative;

              > a {
                position: relative;
                display: block;
                text-decoration: none;
                font-size: 1.2rem;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.075rem;
                color: $preto100;
                padding: 1rem;
                transition: 0.3s;

                @media screen and (min-width: $screen-md) {
                  &:hover {    
                    &::before {
                      display: block;
                    }
                  }
                }

                @media screen and (max-width: $screen-md) {
                  color: $white;
                }
  
                &::before {
                  display: none;
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 1rem;
                  height: 6px;
                  width: calc(100% - 2rem);
                  z-index: 1;
                  background-color: $primary;
                  border-radius: 5px;

                  @media screen and (max-width: $screen-md) {
                    background-color: $secondary;
                  }
                }
              }
  
              &.current-menu-item {
                > a {

                  @media screen and (max-width: $screen-md) {
                    color: $white;
                  }

                  &::before {
                    display: block;
                  }
                }
              }

              > ul {
                display: none;
                position: absolute;
                top: 42px;
                left: 0;
                flex-direction: column;
                width: 200px;
                background-color: $white;
                box-shadow: $shadow-menu;
                border-radius: 5px;

                @keyframes showSubMenu {
                  from {
                    opacity: 0;
                    transform: translate3d(-24px, 0, 0);
                  }
                
                  to {
                    display: block;
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                  }
                }

                &.active {
                  display: block;
                  animation: showSubMenu .3s forwards;
                }

                @media screen and (max-width: $screen-md) {
                  position: static;
                  width: 100%;
                  box-shadow: none;
                }

                > li.menu-item-has-children {
                  @media screen and (min-width: $screen-md) {
                    > a {
                      &::after {
                        transform: rotate(225deg);
                      }
                    }
                  }
                }

                > li {

                  > a {
                    text-transform: capitalize;
                    
                    @media screen and (min-width: $screen-md) {
                      &:hover { 
                        background-color: $preto05;
  
                        &::before {
                          display: none;
                        }
                      }
                    }

                    @media screen and (max-width: $screen-md) {
                      color: $primary;
                    }
                  }

                  &.current-menu-item {
                    > a {
                      @media screen and (min-width: $screen-md) {
                        &:hover { 
                          &::before {
                            display: block;
                          }
                        }
                      }

                      @media screen and (max-width: $screen-md) {
                        color: $primary;
                      }
                    }
                  }

                  > ul {
                    top: 0;
                    left: 200px;

                    @media screen and (max-width: $screen-md) {
                      background-color: $preto10;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
