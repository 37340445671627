.areasexpertise-app {
  padding: 3rem 0 0;
  text-align: center;
  color: $white;

  .list {
    display: flex;
    flex-wrap: wrap;
    
    > .item {
      display: flex;
      flex: 1;
      padding: 0 15px;
    }
  }
}