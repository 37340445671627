.tabs-app {
  > .tabs {
    margin-bottom: 1.5rem;
    
    > .list {
      display: flex;
  
      > .item {
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }

        &.-active {
          > .link {
            background-color: $primary;
            color: $white;
          }
        } 

        > .link {
          display: block;
          font-size: 1.4rem;
          font-weight: bold;
          text-transform: uppercase;
          padding: 1.5rem;
          background-color: $white;
          color: $primary;
          text-decoration: none;
          border: 1px solid $primary;

          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }

  > .contents {
    > .content {
      opacity: 0;
      visibility: hidden;
      height: 0;
      transform: translate3d(-3rem, 0, 0);

      &.-active {
        opacity: 1;
        visibility: visible;
        height: auto;
        transform: translate3d(0, 0, 0);
        transition: .3s;
      }
    }
  }
}