.media-app {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  box-shadow: $shadow-botao;
  border-radius: 5px;

  @media screen and (max-width: $screen-sm) {
    margin-bottom: 1.5rem;

    &.-m-mt1rem {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  &.-blog {
    padding-bottom: 30%;
  }

  &.-callblog {
    padding-bottom: 75%;
  }

  &.-noshadow {
    box-shadow: none;
  }

  &.-mb1rem {
    margin-bottom: 1rem;
  }

  &.-mb2rem {
    margin-bottom: 2rem;
  }


  &.-vertical {
    padding-bottom: 160%;
  }

  &.-ovisible {
    overflow: visible;
  }

  &.-fullarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  &.-propimg {
    box-shadow: none;
    padding: 0;
    height: auto;

    > .link {
      display: block;
      width: 100%;

      > img {
        display: block;
        position: static;
        width: 100%;
      }
    }

    > img {
      display: block;
      position: static;
      width: 100%;
    }
  }

  > iframe, > img, > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .tag {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: $white;
    background-color: $secondary;
    border-radius: 1rem;
    box-shadow: $shadow-card-elevado;
    padding: 1rem;
    text-align: center;
  }
}