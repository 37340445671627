.totalizers-app {
  position: relative;
  padding: 3rem 0;
  background-size: cover;
  background-position: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(271.23deg, rgba($primary4, .9) 0%, rgba($primary, .9) 100%);
  }

  .totalizers {
    margin-top: 3rem;

    > .list {
      display: flex;

      @media screen and (max-width: $screen-sm) {
        flex-direction: column;
      }

      > .item {
        flex: 1;
        margin-right: 3rem;

        @media screen and (max-width: $screen-sm) {
          margin-right: 0;
          margin-bottom: 1.5rem;
        }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: $screen-sm) {
            margin-bottom: 0;
          }
        }

        > .overline {
          color: $white;
          font-size: 1.4rem;
          line-height: 1.42;
          letter-spacing: .75;
          text-transform: uppercase;
          font-weight: 700;
        }

        > .subtitle {
          background-color: $secondary;
          padding: .5rem 1rem;
          color: $white;
          border-radius: 5px;
          text-align: center;
          font-size: 2rem;
          line-height: 1.46;
          font-weight: 700;
        }
      }
    }
  }
}