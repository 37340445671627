.aboutus-app {
  background-color: $white;
  padding: 3rem 0;
  box-shadow: $shadow-card;

  strong,
  p {
    margin-bottom: 3rem;
  }

  img {
    @media screen and (max-width: $screen-sm) {
      margin-top: 3rem;
    }
  }
}
