.list-contact {
  > .list {
    > .item {
      display: flex;
      align-items: baseline;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      > .link {
        display: flex;
        align-items: baseline;
        color: $preto100;
        text-decoration: none;

        &:hover {
          color: $primary;
        }

        > i {
          margin-right: .5rem;
          color: $primary;
        }
      }
    }
  }

  &.-footer {
    > .list {
      > .item {
        > i {
          margin-right: 1rem;
        }

        > .link {
          color: $white;
  
          &:hover {
            text-decoration: underline;
          }
  
          > i {
            margin-right: 1rem;
            color: $white;
          }
        }
      }
    }
  }
}