.author-app {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  > .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5rem;
    height: 4.5rem;
    background-color: $preto10;
    border-radius: 50%;
    margin-right: 1rem;
    overflow: hidden;

    > img {
      width: 4.5rem;
      height: 4.5rem;
      object-fit: cover;
    }
  }
}