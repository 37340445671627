.slider-app {
  position: relative;
  color: $white;

  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 6rem;
    height: 6rem;
    border-radius: 5px;
    box-shadow: $shadow-botao;
  
    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .slick-prev {
    background: rgba($black, .3) url("./images/chevron-left.svg") center no-repeat;
    background-size: 1.5rem 3rem;
  
    &:hover, &:focus {
      background: rgba($black, .3) url("./images/chevron-left.svg") center no-repeat;
      background-size: 1.5rem 3rem;
    }
  
    &:hover {
      opacity: .8;
    }
  }
  
  .slick-next {
    background: rgba($black, .3) url("./images/chevron-right.svg") center no-repeat;
    background-size: 1.5rem 3rem;
  
    &:hover, &:focus {
      background: rgba($black, .3) url("./images/chevron-right.svg") center no-repeat;
      background-size: 1.5rem 3rem;
    }
  
    &:hover {
      opacity: .8;
    }
  }

  .slick-dots {
    button[aria-label="1 of 1"] {
      display: none;
    }
  }

  &.-header {
    .slide {
      position: relative;
      display: flex !important;
      align-items: center;
      min-height: calc(100vh - 14.5rem);
      max-height: calc(100vh - 14.5rem);
      background: url("./images/bg-slide.png") center no-repeat;
      background-size: cover;
      padding: 3rem 0;

      &.overlay::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.6);
      }

      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      &.slick-active {
        > .container {
          .content {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      }
  
      > .container {
        position: relative;
        z-index: 1;
  
        .content {
          opacity: 0;
          transition-duration: 1s;
          transition-delay: .4s;
          transform: translate3d(0, 3rem, 0);
  
          > .title {
            font-size: 6rem;
            font-weight: 700;
            line-height: 1.46;
            letter-spacing: -1%;
            margin-bottom: 1.5rem;
            text-transform: uppercase;
          }
  
          > .text {
            font-size: 2rem;
            line-height: 1.5;
            margin-bottom: 3rem;
          }
        }
      }
    }

    .slick-prev {
      left: 3rem;
    }
    
    .slick-next {
      right: 3rem;
    }
  }

  &.-servicos {
    position: relative;
    color: $preto100;
    margin-bottom: 3rem!important;

    .slide {
      > .row {
        margin-left: 0;
        margin-right: 0;

        > div[class^="col"] {
          padding: 0;

          &:first-child {
            padding-left: 3px;
            margin-top: 6rem;
            margin-bottom: 6rem;

            @media screen and (max-width: $screen-sm) {
              display: none!important;
            }
          }
  
          &:last-child {
            padding: 3rem 0;

            @media screen and (max-width: $screen-sm) {
              padding: 0;
            }
          }
  
          > .imgslide {
            display: flex;
            border-radius: 5px;
            box-shadow: $shadow-card;
            overflow: hidden;
            margin-right: -4.5rem;
            
            > img {
              width: 100%;
              object-fit: cover;
            }
          }
  
          > .card-app {
            margin-bottom: 1.5rem;
  
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .slick-prev {
      left: -9rem;
    }
    
    .slick-next {
      right: -9rem;
    }

    .slick-dots {
      bottom: 0;
      line-height: 0;
    }
  }

  &.-certificates {
    .slick-dots {
      bottom: 0;
    }
  }

  &.-products {
    color: $preto100;

    .slick-prev {
      left: -7.5rem;
    }
    
    .slick-next {
      right: -7.5rem;
    }
  }
}