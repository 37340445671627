.float-whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 3rem;
  bottom: 3rem;
  z-index: 10;
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  line-height: 1;
  border: none;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  background-color: #34af23;
  color: $white;
  box-shadow: 0 8px 16px rgba($black, 0.4);
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:active {
    transform: scale(0.9);
  }

  &:hover {
    opacity: 0.8;
    color: $white;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }
}
