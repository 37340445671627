$white: #ffffff;
$black: #000000;

$preto100: #464646;
$preto50: #A3A3A3;
$preto30: #C7C7C7;
$preto10: #ECECEC;
$preto05: #F6F6F6;

$primary: #005386;
$primary1: #0197F0;
$primary2: #0069A7;
$primary3: #00436A;
$primary4: #002F4B;

$secondary: #D27400;

$mineShaft: #333333;
$mercury: #e5e5e5;
$gray: #808080;

$goldenGrass: #d6a626;
$salomie: #ffdc7f;
$butteredRum: #ab8112;
$brown: #846000;
