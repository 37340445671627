/* SLICK SLIDER */
.slider-depositions {
  .slick-prev {
    left: -6rem;
  }
  
  .slick-next {
    right: -6rem;
  }

  .slick-prev {
    background: url("./images/chevron-left-depositions.svg") center no-repeat;
  
    &:hover, &:focus {
      background: url("./images/chevron-left-depositions.svg") center no-repeat;
    }
  }

  .slick-next {
    background: url("./images/chevron-right-depositions.svg") center no-repeat;
  
    &:hover, &:focus {
      background: url("./images/chevron-right-depositions.svg") center no-repeat;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-dots {
  bottom: 1.5rem;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-dots li {
  width: auto;
  height: auto;

  &.slick-active {
    button {
      width: 6rem;
      background-color: $secondary;
    }
  }

  button {
    height: 6px;
    width: 3rem;
    border: none;
    background-color: $preto30;
    border-radius: 5px;

    &::before {
      display: none;
    }
  }
}

/* CONTACT FORM 7 */

.wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 1.5rem;
}

.wpcf7 form .wpcf7-response-output {
  margin: 1.5rem 0 !important;
}

.wpcf7 .ajax-loader, .wpcf7-spinner {
  position: absolute !important;
}

/* WORDPRESS */

.wp-block-image img {
  height: auto;
}

.wp-block-columns {
  margin-bottom: 0!important;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 3rem!important;
}

.wp-block-separator {
  border-color: $preto10!important;
}

@media screen and (max-width: $screen-sm) {
  .wp-block-image .alignright,
  .wp-block-image .alignleft {
    float: none!important;
  }
}

 
/* SENDPULSE */

.sp-form[sp-id="225758"] {
  background: transparent!important;
  border: none!important;
  padding: 0!important;
}

.sp-form .sp-field {
  padding: 0!important;
}

.sp-form[sp-id="225758"] .sp-field label {
  display: none;
}

.sp-form-horizontal .sp-element-container .sp-field.sp-field-full-width {
  display: none;
}

.sp-form[sp-id="225758"] .sp-button {
  background-color: transparent!important;
  border-radius: 5px!important;
  border: 1px solid $white;
  margin-top: 1rem;
}

.sp-form[sp-id="225758"] .sp-button:hover {
  background-color: $white!important;
  color: $primary;
  top: 0!important;
}

.sp-form a {
  display: none;
}

.sp-message.sp-message-success {
  border: 1px solid #46b450;
  margin: 0!important;
  padding: 1rem!important;

  h3 {
    color: $white!important;
  }
}

.sp-field {
  &.sp-field-full-width {
    > div {
      display: none;
    }
  }
}