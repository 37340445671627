.form-contact {
  margin-top: 3rem;

  @media screen and (max-width: $screen-sm) {
    margin-bottom: 3rem;
  }
  
  .row {
    margin-right: -.75rem;
    margin-left: -.75rem;

    > .col-sm-6 {
      padding: 0 .75rem;
    }
  }
}