.card-app {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-card;
  margin-bottom: 3rem;
  background-color: $white;
  border-radius: 5px;

  > .menu {
    > ul {
      > li {
        margin-bottom: 1rem;

        &.current-menu-item,
        &.current-page-ancestor {
          > a {
            color: $white;
            background-color: $primary;
          }
        }

        > a {
          display: block;
          padding: 1.5rem;
          background-color: $white;
          border: 1px solid $primary;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 1.4rem;
          font-weight: bold;
          color: $primary;
          transition: .3s;

          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }

  &.-p3rem {
    padding: 3rem;
  }

  &.-nomargin {
    margin: 0;
  }

  &.-aicenter {
    align-items: center;
  }

  &.-jccenter {
    justify-content: center;
  }

  &.-areaexpertise {
    width: 100%;
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
    text-decoration: none;

    > .icon {
      margin-bottom: 1.5rem;

      > img {
        display: block;
        width: 6rem;
        height: 6rem;
        object-fit: cover;
        margin: 0 auto;
      }
    }

    > .title-app {
      margin: 0;
    }
  }

  &.-case {
    text-decoration: none;
    color: $preto100;
    transition: .3s;

    &:hover {
      transform: scale(1.1);
      box-shadow: $shadow-card-elevado;
    }

    > .media-app {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
    }
  }

  &.-blog {
    width: 100%;
    text-decoration: none;
    color: $preto100;
    transition: .3s;

    &:hover {
      transform: scale(1.1);
      box-shadow: $shadow-card-elevado;
    }

    > .media-app {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
    }

    > .text {
      > .authortime {
        display: flex;
      }

      > .excerpt {
        > p {
          margin-bottom: 0;
        }
      }
    }

  }

  &.-service {
    width: 100%;
    color: $preto100;
    text-decoration: none;
    transition: .3s;

    &:hover {
      transform: translate3d(-3rem, 0, 0);
      box-shadow: $shadow-card-elevado;
    }

  
    > .title {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      > .icon {
        margin-right: 1rem;

        > {
          img {
            display: block;
            width: 5rem;
            height: 5rem;
            object-fit: cover;
          }
        }
      }

      > .title-app {
        margin: 0;
      }
    }
  }

  &.-certificates {
    margin-bottom: 1px;

    > .image {
      max-width: 16.5rem;
      width: 100%;
    }
  }

  &.-news, &.-projects {
    text-decoration: none;
    transition: .2s;
    color: $preto100;

    &:hover {
      transform: scale(1.1);
      box-shadow: $shadow-card-elevado;
      z-index: 100;
    }
  
    > .imagebox {
  
      > .image {
        width: 100%;
      }
    }
  
    > .text {
      padding: 3rem;

      > .title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.46;
        margin-bottom: 1.5rem;
      }
    }
  }

  &.-aside {
    > .lastestnews {
      > .new {
        > .list {
          > .item {
            display: flex;
            border-bottom: 1px solid $preto10;
            padding: 1.5rem 0;
  
            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              border-bottom: none;
            }
  
            > .imagebox {
              margin-right: 1.5rem;
      
              > .image {
                width: 7.8rem;
                height: 7.8rem;
                object-fit: cover;
              }
            }
            > .text {
              > .title {
                font-size: 1.6rem;
                line-height: 1.62;
                font-weight: bold;
      
                > .link {
                  color: $primary;
                  text-decoration: none;
      
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.-unit {
    .list {
      padding: 0;
      margin: 0;
      list-style-type: none;
      
      > .item {
        margin-bottom: 1rem;
      }
    }
  }

}