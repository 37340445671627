.contact-about {
  flex-direction: row;
  justify-content: space-between;

  @media screen and(max-width: $screen-sm) {
    flex-direction: column;
  }

  > .infobox {
    @media screen and(max-width: $screen-md) {
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
    
    > .title {
      display: flex;
      align-items: center;
      font-size: 2.4rem;
      line-height: 1.46;
      font-weight: bold;
      margin-bottom: 1rem;

      svg {
        margin-right: 1rem;
      }
    }
  }
}