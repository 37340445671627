.info-contact {
  > .list {
    > .item {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      > .link,
      > .info {
        display: flex;
        align-items: center;
        color: $mineShaft;
        text-decoration: none;

        > i {
          font-size: 3rem;
          margin-right: 1.5rem;
          color: $butteredRum;
        }

        > .text {
          > .title {
            font-weight: 700;
          }
        }
      }

      > .link {
        > .text {
          > .data {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &.-pt-3 {
    padding-top: 3rem;
  }
}
