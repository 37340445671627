.js-scroll {
  opacity: 0;
  transition: 1s;

  &.slide-right {
    transform: translate3d(-3rem, 0, 0);
  }

  &.slide-left {
    transform: translate3d(3rem, 0, 0);
  }

  &.slide-up {
    transform: translate3d(0, 3rem, 0);
  }

  &.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
