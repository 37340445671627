.search-form {
  position: relative;
  margin-bottom: 3rem;

  button {
    position: absolute;
    bottom: 0;
    right: 0;
    background: transparent;
    border: none;
    width: 5.6rem;
    height: 5.6rem;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transform: scale(0.9);
    }
  }
}
