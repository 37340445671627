.slogan-app {
  padding: 3rem 0;
  text-align: center;
  color: $white;
  font-size: 2rem;

  .text {
    > p {
      margin-bottom: 1.5rem;
    }
  }
}