.copy-app {
  margin-top: 3rem;
  padding: 1.5rem 0;
  color: $white;
  background-color: rgba(0,0,0, .3);
  font-size: 1.4rem;
  line-height: 1.42;

  a {
    color: $white;

    &:hover {
      color: $secondary;
    }
  }
}