.title-app {
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: $preto100;

  > b, i, span, strong {
    font-weight: 700;
    color: $secondary;
  }

  &.-heading1 {
    font-size: 6rem;
    line-height: 1.46;
  }

  &.-heading2 {
    font-size: 3.4rem;
    line-height: 1.46;
  }

  &.-heading3 {
    font-size: 2.4rem;
    line-height: 1.46;
  }

  &.-heading4 {
    font-size: 2rem;
    line-height: 1.46;
  }

  &.-section {

    &::after {
      display: block;
      content: "";
      width: 6rem;
      height: 6px;
      background-color: $primary;
      margin-top: .5rem;
      border-radius: 5px;
    }

    &.-bgdark {
      &::after {
        background-color: $secondary;
      }
    }
  }

  &.-center {
    text-align: center;
    
    &::after {
      margin: 0 auto;
    }
  }

  &.-ttnone {
    text-transform: none;
  }

  &.-nomargin {
    margin: 0;
  }

  &.-white {
    color: $white;
  }

  &.-primary {
    color: $primary;
  }

  &.-secondary {
    color: $secondary;
  }
}
