.main-app {
  flex: 1;
  padding-top: 14.5rem;

  &.-fiorde {
    .history {
      background: linear-gradient(271.23deg, #002F4B 0%, #005386 100%);
      color: $white;

      > .bgcircles {
        padding: 3rem 0;
        background: url('images/bg-linha-tempo-Fiorde.png') no-repeat top / cover;
      }
    }

    .main {
      padding-top: 3rem;
    }
  }

  &.-plataforma-sirius {
    .infographic {
      background: linear-gradient(271.23deg, #040E32 0%, #01283F 100%);
      color: $white;
      padding: 3rem 0;
      margin-bottom: 3rem;
    }
  }
}