.page-title {
  position: relative;
  padding: 6rem 0;

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .6);
    z-index: 0
  }

  > .video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .container {
    position: relative;
    z-index: 1;
  }
}