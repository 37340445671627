@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url("fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fonts/Montserrat-Black.ttf") format("truetype");
}
