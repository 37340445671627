.footer-app {
  background: linear-gradient(271.23deg, $primary4 0%, $primary 100%);
  padding: 3rem 0 0;

  .logo {
    height: 5rem;
    margin-bottom: 1rem;
  }

  .list {;
    > ul {
      > li {
        display: flex;
        margin-bottom: 1.5rem;
        align-items: center;

        &::before {
          display: block;
          content: url(./images/angle-right.svg);
          width: 7px;
          height: 20px;
          margin-right: 1rem;
        }

        > a {
          color: $white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
