.breadcrumb-app {
  font-size: 1.6rem;
  line-height: 1.62;
  color: $white;

  @media screen and (max-width: $screen-sm) {
    overflow-y: auto;
  }

  a {
    color: $white;
    text-decoration: none;
    font-weight: normal;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  .breadcrumb_last {
    font-weight: 700;
  }

  ul {
    display: flex;
    padding: 0;
    margin: 0;

    > li {
      @media screen and (max-width: $screen-sm) {
        white-space: nowrap;
      }

      &:last-child {
        font-weight: 700;
      }

      a {
        color: $white;
        text-decoration: none;
        font-weight: normal;
    
        &:hover {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }
}