/* FONTAWESOME */
$fa-font-path: "fonts";

/* SCREEN */
$screen-xs: 576px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

/* SHADOWS */
$shadow-card: 0 1px 2px rgba(black, .2);
$shadow-botao: 0 2px 4px rgba(black, .2);
$shadow-menu: 0 4px 8px rgba(black, .2);
$shadow-card-elevado: 0 8px 16px rgba(black, .2);
$shadow-modal: 0 16px 32px rgba(black, .2);
