.contact-aside {
  > .infobox {
    margin-bottom: 3rem;
    
    > .title {
      display: flex;
      align-items: center;
      font-size: 2.4rem;
      line-height: 1.46;
      font-weight: bold;
      margin-bottom: 1rem;

      svg {
        margin-right: 1rem;
      }
    }
  }
}